import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectOrdersRouterExtras } from '../orders/orders.selectors';
import {
  selectIsAgent,
  selectIsManager,
  selectOrganisation,
  selectSimpleConsumer,
  selectTerminal,
  selectUserLocation,
} from '../user/user.selectors';
import { GlobalState, globalFeatureKey } from './global.reducer';
import { PrivacyLevel } from 'src/app/shared/models/user';

export const selectState = createFeatureSelector<GlobalState>(globalFeatureKey);

export const selectGlobalLanguage = createSelector(
  selectState,
  (state) => state?.language,
);

export const selectShowConsumerInfo = createSelector(
  selectTerminal,
  selectSimpleConsumer,
  selectIsManager,
  selectIsAgent,
  selectOrdersRouterExtras,
  (term, unauth, manager, agent, item) =>
    !!(
      ((manager || agent) && (term || item)) ||
      (term && term.privacy !== PrivacyLevel.ANONYMOUS) ||
      (unauth &&
        (unauth.location_detail?.unauth_privacy !== PrivacyLevel.ANONYMOUS ||
          (!unauth.location &&
            unauth.organisation.unauth_privacy !== PrivacyLevel.ANONYMOUS)))
    ),
);

export const selectShowConsumerInfoDetail = createSelector(
  selectTerminal,
  selectSimpleConsumer,
  selectIsManager,
  selectIsAgent,
  selectOrdersRouterExtras,
  (term, unauth, manager, agent, item) =>
    !!(
      ((manager || agent) && item) ||
      (term && term.privacy === PrivacyLevel.SHOW_ALL) ||
      (unauth &&
        (unauth.location_detail?.unauth_privacy === PrivacyLevel.SHOW_ALL ||
          (!unauth.location &&
            unauth.organisation.unauth_privacy === PrivacyLevel.SHOW_ALL)))
    ),
);

export const selectFormErrors = createSelector(
  selectState,
  (state) => state?.formErrors,
);

export const selectAutocompleteSearch = createSelector(
  selectState,
  (state) => state?.autocompleteSearch,
);

export const selectGlobalError = createSelector(
  selectState,
  (state) => state?.global_error,
);
